<template>
  <div>
    <topmenu :comname="'forum'"></topmenu>
    <div class="widht1240">
      <div class="t-map">
        您当前的位置：
        <span>同期活动</span>
      </div>
      <div class="widht200 fl">
        <contact></contact>
      </div>
      <div class="widht1030 bg-white fr">
        <div v-if="isshowlist" class="listinfor" v-loading="loading">
          <ul>
            <li v-for="item in list" :key="item.id">
              <router-link :to="{name:'forumdetails',query:{id:item.id}}" class="imgwid">
                <el-image :src="item.vImgUrl" fit="contain" style="width:314px; height:160px;" :title="item.vName" :alt="item.vName"></el-image>
              </router-link>
              <div class="contm">
              <router-link :to="{name:'forumdetails',query:{id:item.id}}">
                <span class="tlew" :title="item.vName">{{item.vName.length>30?comJs.limitNum(item.vName,30):item.vName}}</span>
              </router-link>
              <div class="lun">{{item.vAbstract!=null&&item.vAbstract.length>50?comJs.limitNum(item.vAbstract,50):item.vAbstract}}
                <router-link :to="{name:'forumdetails',query:{id:item.id}}">
                <span class="red">[查看更多]</span>
              </router-link></div>
              <div class="time">{{item.dCreateTime | formatDate}}</div>
              </div>
            </li>
          </ul>
          <el-pagination
            background
            layout="prev, pager, next"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :page-count="totalPage"
            :current-page="currentPage"
            :page-size="pagesize"
            :hide-on-single-page="true"
            class="t_page"
          ></el-pagination>
        </div>
        <nocont v-else></nocont>
      </div>
    </div>
    <bottominfo></bottominfo>
  </div>
</template>
<script>
import topmenu from "@/components/public/top";
import bottominfo from "@/components/public/bottom";
import contact from "@/components/public/contact";
import nocont from "@/components/public/nocont";
export default {
  name: "forum",
  data() {
    return {
      id: "",
      list: [],
      isshowlist:true,
      loading:true,
      currentPage: 1, //当前页
      pagesize: 6, //每页显示条数
      totalPage: 0 //总页数
    };
  },
  components: {
    topmenu,
    bottominfo,
    contact,
    nocont
  },
  created() {
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
    }
    this.getinformation();
  },
  watch: {
    $route: function(to, from) {
      this.id = this.$route.query.id;
    },
    //监听翻页
    currentPage: function(newVal, oldVal) {
      this.loading = true;
      this.getinformation();
    }
  },
   filters: {
    formatDate: function(value) {
      let date = new Date(value);
      let y = date.getFullYear();
      let MM = date.getMonth() + 1;
      MM = MM < 10 ? "0" + MM : MM;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      return y + "-" + MM + "-" + d;
    }
  },
  methods: {
    handleSizeChange: function(size) {
      //翻页
      this.pagesize = size;
    },
    handleCurrentChange: function(currentPage) {
      //翻页
      this.currentPage = currentPage;
    },
    getinformation() {
      let data = {
        current: this.currentPage,
        size: this.pagesize,
        isPage: true
      };
      this.$axios
        .post("/api/app/exhibitionForum/getExhibitionForumPageList", data)
        .then(res => {
          this.loading=false;
          this.totalPage=res[1].pages;
          if (res[0].length > 0) {
            let infordata = res[0];
            this.list = infordata;
            console.log(this.list);
            this.isshowlist=true
            }else{
            this.isshowlist=false
            }
        });
    }
  }
};
</script>
<style scoped>
.listinfor{
  padding: 40px 0;
}
.listinfor ul{
  display: block;
  overflow: hidden;
  padding-left: 14px;
}
.listinfor li{
  width: 314px;
  float: left;
  margin-left: 15px;
  margin-bottom: 15px;
}
.imgwid{
  display: block;
  height: 160px;
}
.contm{
  background: #fafafa;
  margin-top: 5px;
  padding: 10px;
  text-align: left;
  height: 150px;
}
.lun{
  line-height: 22px;
  padding: 10px 0;
  color: #666;
}
.tlew{
  font-size: 18px;
  line-height: 22px;
  color: #333;
}
.time{
  font-size: 15px;
  color: #999;
}

.t_page{
  margin-top: 30px;
}
</style>